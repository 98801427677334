import axios from '@shared/services/api/axiosConfig.js';

// const debug = process.env.NODE_ENV !== 'production';

export default {
  async searchCharities(searchTerm){
    const res = await axios.get(`/api/external/demo/charity`, { params: { query: searchTerm } });
    return res;
  },
  async getCharity(regNo){
    const res = await axios.get(`/api/external/demo/charity/${regNo}`)
    return res;
  },
  async postGetOfflineFormDoc(proposalUnique){
    const res = await axios.post(`/api/external/proposal/${proposalUnique}/downloadOffline`)
    return res;
  },
}
