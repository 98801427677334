<template>
  <div class='mt-5 mb-5 pg-break-print'>
    <h2 :id='elementId'>{{text}}</h2>
    <div v-for='content in displayComponents' :key='content.id'>
      <PrintItemHolder :proposalItem="content"></PrintItemHolder>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "QuestionSectionPrint",
  data() {
    return {
      displayComponents: []
    }
  },
  props: {
    id: Number,
    text: String,
    type: String,
  },
  created () {
    this.getVisibleComponents();
  },
  computed: {
    ...mapState('fill', ['answers' ]),
    elementId() {
      return this.type + this.id;
    },
  },
  methods: {
    getVisibleComponents(){   
      this.displayComponents = this.$store.getters['fill/filteredItems'](this.id);
    }
  },
}
</script>

<style scoped>

</style>
