import Vue from 'vue'
import Router from 'vue-router'


// CLient area
import ClientProposalFillPage from '@survey/fill/pages/ClientProposalFillPage.vue'
import ClientLandingPage from '@survey/fill/pages/ClientLandingPage.vue'
import ClientCompletedPage from '@survey/fill/pages/ClientCompletedPage.vue'


// System
import Page404 from '@shared/components/coreUi/Page404.vue'
import PuppetPrintPage from '@survey/puppet/pages/PuppetPrintPage.vue'


// Wholesale
import GenerateLinkPage from '@survey/wholesale/pages/GenerateLinkPage.vue'
import GeneratedLinkResultPage from '@survey/wholesale/pages/GeneratedLinkResultPage.vue'

Vue.use(Router)
 
export default new Router({
  mode: 'history',
  routes: [
    // Catch all
    {
      path: '*',
      component: Page404
    },

    // Clients
    {
      path: '/landing/:proposalUniqueLink',
      component: ClientLandingPage,
      props: true
    },
    {
      path: '/form/:proposalUniqueLink',
      component: ClientProposalFillPage,
      props: true
    },
    {
      path: '/completed/:proposalUniqueLink',
      component: ClientCompletedPage,
      props: true
    },

    // System
    {
      path: '/puppet/:formUid',
      component: PuppetPrintPage,
      props: true
    },

    // Wholesale
    {
      path: '/r/:tenant/wholesale',
      component: GenerateLinkPage,
      props: ({ params }) => ({ tenant: params.tenant })
    },
    {
      path: '/r/:tenant/:templateId/result',
      component: GeneratedLinkResultPage,
      props: route => ({ templateId: parseInt(route.params.templateId), tenant: route.params.tenant, link: route.query.r })
    }
  ]
});
