<template>
  <fieldset :id='elementId'>
    <legend tabindex='-1' class='question-label col-form-label pt-0  bv-no-focus-ring'>{{question}}</legend>
    <div v-if="hasSelected">
      <ul class='fa-ul list'>
        <li class='list-item'>
          <span class='fa-li'>
            <i class='fas fa-check-circle'></i>
          </span>
          {{ selected }}
        </li>
      </ul>
    </div>
    <div v-else>
      <ul class='fa-ul list'>
        <li class='list-item'>
          <span class='fa-li'>
            <i class="fas fa-times-circle"></i>
          </span>
          No Option Selected
        </li>
      </ul>
    </div>
  </fieldset>
</template>

<script>

export default {
  props: {
    id: Number,
    question: String,
    options: Array,
    type: String,
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    hasSelected(){
      let selected = this.$store.getters['fill/answer'](this.id);
      return (selected !== null && selected !== undefined && selected !== '')
    },
    selected() {
      return this.$store.getters['fill/answer'](this.id);
    }
  },
  methods: {
    optionId(idx) {
      return this.elementId + '_' + idx;
    }
  }
};
</script>

<style scoped>

ul {
  list-style: none;
  padding: 0;
  margin-left: 24px;
  margin-inline-start: 27px;
}

</style>