import { isEmpty } from '@shared/services/utils/stringUtils.js';

export function updatePageTitle(appEnvironment, proposalName) {
  let name = proposalName;
  if(!isEmpty(proposalName)){
    name = proposalName + ' - ';
  }
  if (appEnvironment === 'local-standalone') {
    document.title = 'DEV - ' + name + 'SwiftProposals | Brokit';
  } else {
    document.title = name + 'SwiftProposals | Brokit';
  }
}
