<template>
  
  <div class="container page text-center" v-if='loadingState.dataFound'>  
    <img v-if="hasLandingImg" class="land-img" :src="landingImageSrc" alt="Landing Image" />
    <h1 class="client-landing-title">{{displayTitle}}</h1>
    <h2>{{clientName}}</h2>
    <p>
      {{landingText}}
    </p>

    <div class="pb-5 pt-3">
      <VEditButton class='eb-prim' @click="onStartForm">Continue</VEditButton>
    </div>
  </div>
  <div class="container" v-else>
    <PageLoading loadingText="Loading Form. Please wait..." v-if="loadingState.loading"></PageLoading>
    <Page404 v-else></Page404>
  </div>
</template>

<script>
import Page404 from '@shared/components/coreUi/Page404.vue';
import PageLoading from '@shared/components/coreUi/PageLoading.vue';

import { updatePageTitle } from '@shared/services/utils/pageTitleHelper.js';
import { mapState } from 'vuex'

export default {
  components: {
    Page404,
    PageLoading
  },
  props: ['proposalUniqueLink'],
  computed:{
    ...mapState('meta', ['displayTitle', 'landingText', 'clientName', 'loadingState', 'proposalState','landingImage', 'hasLandingPage']),
    hasLandingImg(){
      return !this.$utils.str.isEmpty(this.landingImage)
    },
    landingImageSrc(){
      return this.$api.apiPathStart() + `/api/external/proposal/landingimage/${this.landingImage}`
    }
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    fetchData() {
      // remember to bind this for dispatch
      this.$store.dispatch('meta/FETCH_META', this.proposalUniqueLink).then(function() {
        if(this.proposalState === 'Completed'){
            // completed so redirect to completed page
            this.$store.commit("meta/setIsRedirect", true);
            this.$router.push(`/completed/${this.proposalUniqueLink}`);
        }

        if(this.hasLandingPage === false){
            // no landing page so redirect to form
          this.onStartForm();
        }

        updatePageTitle(this.$store.getters.environmentMenuBar, this.displayTitle);
      }.bind(this));
    },
    onStartForm(){
      this.$router.push(`/form/${this.proposalUniqueLink}`);
    }
  },
};
</script>

<style scoped>
.client-landing-title {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.1;
}

@media only screen and (min-width: 40em) {
  .client-landing-title {
    font-size: 3rem;
  }
}

.help-img {
  height:232px;
  width:210px;
}

.center-me {
  margin: 0 auto;
}

.land-img {
  object-fit: cover;
  height: 40vh;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.help-text {
  text-align: left
}
</style>
