<template>
  <div class='appendix-comment'>
    <div class='appendix-comment-text'>{{message}}</div>
    <div class='appendix-comment-footer d-flex justify-content-between'>
      <span class='comment-author'>{{author}}</span>
      <span class='comment-date'>{{postedAt}}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'VCommentPrint',
  props: {
    author: String,
    createdAt: Date,
    message: String
  },
  computed: {
    postedAt() {
      return moment(this.createdAt).format('MMMM Do YYYY HH:mm');
    },
    avatarColour() {
      return this.author === 'Broker' ? '#41337a' : '#6ea4bf';
    }
  }
}
</script>

<style scoped>
.appendix-comment {
  margin-bottom: 10px;
  position: relative;
}

.appendix-comment-text {
  font-size: 0.8rem;
  margin-bottom: 10px;
}

.appendix-comment-footer {
  font-size: 0.7rem;
  font-style: italic;
}
</style>