import axios from '@shared/services/api/axiosConfig.js';
const debug = process.env.NODE_ENV !== 'production';

export default {
  async getProposalComments(propUniqueLink){
    const res = await axios.get(`/api/external/proposal/${propUniqueLink}/comments`)
    if (debug) console.log('getProposalComments', res);
    return res;
  },
  async postComment(propUniqueLink, payload){
    const res = await axios.post(`/api/external/proposal/${propUniqueLink}/comment`, payload)
    return res;
  },
  async deleteComment(propUniqueLink, commentId){
    const res = await axios.delete(`/api/external/proposal/${propUniqueLink}/comment/${commentId}`, null);
    return res;
  },
}
