<template>
  <div class="container page text-center" v-if="loadingState.dataFound">
    <i class="fa fa-check checkmark"></i>
    <h2 class="client-landing-title">YOU HAVE SUBMITTED THE {{ displayTitle }}</h2>
    <p class='text-exit pt-3'>{{ exitText }}</p>

    <div class="pt-5">
      <p>Need to change answers? Or accidentally completed the form?</p>
      <VEditButton class="eb-prim pt-3 pb-3 mt-2" @click="onReopen">Reopen and make changes</VEditButton>
      <v-dialog />
    </div>
    <div v-if="downloadLinkReady" style="margin-top: 80px">
      <a href="" v-on:click.prevent="onDownloadForm">Download A Copy</a>
    </div>
    <div v-else style="margin-top: 50px">
      <p>{{ prepareText }}</p>
    </div>
  </div>
  <div class="container" v-else>
    <PageLoading loadingText="Loading. Please wait..." v-if="loadingState.loading"></PageLoading>
    <Page404 v-else></Page404>
  </div>
</template>

<script>
import Page404 from '@shared/components/coreUi/Page404.vue';
import PageLoading from '@shared/components/coreUi/PageLoading.vue';
import reopenProposalDialog from '@survey/fill/ui/reopenProposalDialog.js';

import { updatePageTitle } from '@shared/services/utils/pageTitleHelper.js';
import { mapState } from 'vuex';

export default {
  components: {
    Page404,
    PageLoading
  },
  props: ['proposalUniqueLink'],
  data() {
    return {
      pageFound: false,
      requestDone: false,
      downloadLinkReady: false,
      prepareText: 'Preparing a copy for you .'
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData'
  },
  computed: {
    ...mapState('meta', ['proposalName', 'exitText', 'displayTitle', 'loadingState', 'proposalState'])
  },
  methods: {
    fetchData() {
      // remember to bind this for dispatch
      this.$store.dispatch('meta/FETCH_META', this.proposalUniqueLink).then(function() {
        if (this.proposalState === 'Active') {
          // Active so redirect to landing page
          this.$store.commit('meta/setIsRedirect', true);
          this.$router.push(`/landing/${this.proposalUniqueLink}`);
        } else {
          updatePageTitle(this.$store.getters.environmentMenuBar, this.proposalName);
          this.checkDownloadLink();
        }
      }.bind(this));
    },
    checkDownloadLink() {
      if(this.$debug) console.log("CheckDownloadLink");
      this.$api.proposal.getIsDownloadlinkReady(this.proposalUniqueLink).then(res => {
        if (res.data === true) {
          this.downloadLinkReady = true;
        } else {
          setTimeout(
            function() {
              this.prepareText = this.prepareText === 'Preparing a copy .' ? 'Preparing a copy ...' : 'Preparing a copy .';
              this.checkDownloadLink();
            }.bind(this),
            1000
          );
        }
      });
    },
    onDownloadForm() {
      this.$api.proposal.downloadProposal(this.proposalUniqueLink);
    },
    onReopen() {
      const fn = reopenProposalDialog.bind(this);
      fn(this.proposalUniqueLink);
    }
  }
};
</script>

<style scoped>
.client-landing-title {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.1;
  text-transform: uppercase;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.checkmark {
  font-size: 3rem;
  line-height: 1;
  color: #24b663;
}

.text-exit{
  white-space: pre-line;
}

@media only screen and (min-width: 40em) {
  .client-landing-title {
    font-size: 3rem;
  }
  .checkmark {
    font-size: 6rem;
  }
}
</style>
