<template>
  <div :id='elementId' >
    <label :for='elementId +"_in"' class='question-label'>{{question}}</label>
    <input type='text' class='form-control' :id='elementId +"_in"' :ref='elementId' :value='value' @focus='commitFocus'  disabled />
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    question: String,
    type: String
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    value: {
      get() {
        return this.$store.getters['fill/answer'](this.id);
      }
    }
  }
};
</script>

<style scoped>
</style>