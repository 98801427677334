<template>
  <div class='lay-nav-wrapper'>
      <div v-if='formNotExistError' class="container">
        <div class="d-flex justify-content-center align-items-center" style='height:90vh'>
          <h1 class='mt-2'>Unable to find the form</h1>
        </div>
      </div>
    <div v-else class='container'>
      <div class="title-page title-bg portrait" :style="{background: background}">
        <h1 class='margin-title'>{{displayTitle}}</h1>
        <h2 class="mt-3">Applicant: {{clientName}}</h2>
      </div>
      <div v-for='(content, index) in filteredSections' :key='content.id'>
        <QuestionSectionPrint v-bind='content' :index='index' :totalSections='filteredSections.length'></QuestionSectionPrint>
        <hr />
      </div> 
      <div v-if="printAppendix">
        <h1 class='mt-2 pg-break-print'>Appendices</h1>
        <CommentsAppendix></CommentsAppendix>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import QuestionSectionPrint from '@shared/components/questions/ui/QuestionSectionPrint.vue';
import CommentsAppendix from '@survey/puppet/comments/ui/CommentsAppendix.vue';

export default {
  components: {
    QuestionSectionPrint,
    CommentsAppendix
  },
  props: ['formUid'],
  computed: {
    ...mapState('meta', ['displayTitle', 'clientName', 'sidebarColour']),
    ...mapState('fill', [ 'form', 'focus', 'tableOfContents', 'formNotExistError', 'printAppendix']),
    ...mapGetters('fill', ['filteredSections']),
    background(){
      let hexCode = this.sidebarColour;
      if(hexCode === '#214245' || hexCode === '#C535A1'){
        return 'linear-gradient(190deg, #C535A1 23%, #1A1B5E 79%)'
      }
      if(hexCode === '#0076c0'){
        return 'linear-gradient(190deg, #3db4ff, #0076c0 79%)'
      }
      return hexCode ? hexCode : '#214245'; 
    }
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData'
  },
  methods: {
    fetchData() { 
      this.$store.dispatch('meta/FETCH_META', this.formUid);
      this.$store.dispatch('fill/INIT', { proposalIdentifier:this.formUid, controllerLink:'external', editable: false, componentsMode:'PRINT'});
      this.$store.dispatch('comments/INIT', { proposalIdentifier:this.formUid, user:'Client', controllerLink:'external'});
    }
  }
};
</script>

<style scoped>
.container >>> .question-group-print {
  page-break-inside: avoid;
}

.container >>> .pg-break-print{
  page-break-before: always;
}

.pg-break-print{
  page-break-before: always;
}

hr {
  display: none;
}

.margin-title{
  margin-top: 30vh;
}

.title-page {
    position: relative;
    overflow: hidden;
    padding: 0.8in;
    /* page-break-after: always; */
}

.title-bg {
    width: 100%;
    background-size: contain;
    color: #fff;
}

.title-page.portrait {
    width: 100%;
    height: 12.6in;
}
</style>
