import axios from '@shared/services/api/axiosConfig.js';

export default {
  async postGenerateNewFormLink(tenant, templateId, clientName, brokerEmail){
    let payload = { templateId, clientName, brokerEmail }
    const res = await axios.post(`/api/external/tenant/${tenant}/create`, payload)
    return res;
  },  
  async getProposalTemplates(tenant){
    const res = await axios.get(`/api/external/tenant/${tenant}/templates`)
    return res;
  },
}