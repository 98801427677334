const debug = process.env.NODE_ENV !== 'production';

function initialState(){
  return {
    formUid: '',
    proposalName: '',
    blurb: '',
    clientName: '',
    landingText: '',
    landingImage: null,
    proposalState: '',
    exitText: '',
    displayTitle: '',
    hasLandingPage: true,
    companyId: null, // wiped on survey
    sidebarColour: '',
    loadingState: {
      dataFound: false,
      loading: false,
      error: null,
      isRedirect: false,
    }
  }
}

const state = initialState();
export const getters = {
  value: state => {
    return state.value;
  }
};

const mutations ={
  resetState(state){
    if (debug) console.log('resetState');
    Object.assign(state, initialState())
  },
  setLoading(state){
    if (debug) console.log('setLoading');
    state.loadingState.loading = true
  },
  setMeta(state, res) {
    if (debug) console.log('setMeta triggered with', res.data);
    state.proposalState = res.data.state;
    state.formUid = res.data.uniqueLink;


    state.proposalName = res.data.proposalName;
    state.blurb = res.data.blurb;
    state.clientName = res.data.clientName;
    state.landingText = res.data.landingText;
    state.landingImage = res.data.landingImage;
    state.exitText = res.data.exitText;
    state.displayTitle = res.data.displayTitle;
    state.hasLandingPage = res.data.hasLandingPage;
    state.companyId = res.data.companyId;
    state.sidebarColour = res.data.sidebarColour;
  },
  setFailedLoad(state, err){
    if (debug) console.log('setFailedLoad triggered with', err);
    state.loadingState.dataFound = false;
    state.loadingState.loading = false;
    state.loadingState.error = err;
  },
  setLoaded(state){
    if (debug) console.log('setLoaded');
    state.loadingState.dataFound = true;
    state.loadingState.loading = false;
    state.loadingState.error = null;
  },
  setIsRedirect(state, isRedirect){
    if (debug) console.log('setIsRedirect triggered with', isRedirect);
    state.loadingState.isRedirect = isRedirect;
  }
};


const actions = {
  async FETCH_META({commit, state}, formUid) {
    if(state.loadingState.isRedirect === true && formUid === state.formUid){
      //recently loaded data so ignore request
      commit('setIsRedirect', false);
    }else{
      commit('resetState');
      commit('setLoading');
      try {
        let res = await this._vm.$api.proposal.getProposalMeta(formUid);  
        commit('setMeta', res);
        commit('setLoaded');
      } catch (error) {
        commit('setFailedLoad', error);
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};