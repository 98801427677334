export function submitProposalDialog($modal, $toast, $router, $api, formUid, completedPerc, callBackFn){

  let text = "This will submit the completed form to your broker. Are you sure?"
  if(completedPerc < 99){
    text = "<p>This will submit the completed form to your broker. Are you sure?<p>"
          + `<p class='text-center' style='border: 1px solid #d02547;'>You are <b>${completedPerc}</b>% complete</p>`
  }

  $modal.show('dialog', {
    title: 'Submit Application',
    text: text,
    buttons: [
      {
        title: 'Submit',
        handler: () => {
          $modal.hide('dialog')

          $toast('Submiting. This may take a while. Please wait...', {
            timeout: 2000,
            icon: "fas fa-rocket",
            hideProgressBar: true,
          });
          // fire and forget submit request
          $api.proposal.postSubmitForm(formUid);
          
          // After 2 seconds redirect to completed page
          setTimeout(function () { 
            $router.push(`/completed/${formUid}`);
          }, 2000)
        }
      },
      {
        title: 'Back To Form',
        handler: () => {
          $modal.hide('dialog')
          callBackFn();
        }
      }
    ]
  });
}