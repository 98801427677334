<template>
  <div class="lay-nav-wrapper">
    <QuestionsStepperSidebar
      :bookmarks="tableOfContents"
      :focusedIds="focus"
      :active="formNotExistError"
      :autosaving="saving"
      :offline="offline"
      :proposalUniqueLink="proposalUniqueLink"
      :proposalAccessList="proposalAccessList"
    ></QuestionsStepperSidebar>
    <div v-if="formNotExistError || internalIsLoading || isLoading" class="container">
      <PageLoading loadingText="Loading Form. Please wait..." v-if="internalIsLoading || isLoading"></PageLoading>
      <Page404 v-else></Page404>
    </div>
    <div v-else class="container">
      <h1 class="mt-2">{{ displayTitle }}</h1>
      <div>
        <div v-for="(content, index) in filteredSections" :key="content.id">
          <QuestionSection v-bind="content" :index="index" :totalSections="filteredSections.length"></QuestionSection>
          <hr />
        </div>
      </div>
      <div class="lay-options-both mt-5 mb-5">
        <VEditButton class="eb-success btn-block attach-shepherd-submit" @click="onSubmit" :disabled="!submitButtonEnabled"
          >Submit</VEditButton
        >
      </div>
    </div>
    <v-dialog />
    <VFormOffline />
  </div>
</template>

<script>
import { updatePageTitle } from '@shared/services/utils/pageTitleHelper.js';
import { mapState, mapGetters } from 'vuex';

import QuestionSection from '@shared/components/questions/ui/QuestionSection.vue';
import QuestionsStepperSidebar from '@shared/components/stepper/QuestionsStepperSidebar.vue';
import { submitProposalDialog } from '@survey/fill/ui/submitProposalDialog.js';
import VFormOffline from '@shared/components/formFilling/VFormOffline.vue';
import { formTour } from '@survey/fill/pages/ShepherdTour.js';

export default {
  components: {
    QuestionSection,
    QuestionsStepperSidebar,
    VFormOffline,
  },
  name: 'ClientProposalFillPage',
  data() {
    return {
      submitButtonEnabled: true,
      internalIsLoading: true,
      proposalAccessList: [],
    };
  },
  props: ['proposalUniqueLink'],
  computed: {
    ...mapState('fill', ['form', 'focus', 'tableOfContents', 'formNotExistError', 'isLoading', 'saving', 'offline']),
    ...mapState('meta', ['proposalState', 'displayTitle']),
    ...mapGetters('fill', ['filteredSections']),
  },
  created() {
    this.fetchData();
  },
  beforeDestroy() {
    this.unregisterForAnswerUpdates();
  },
  watch: {
    $route: 'fetchData',
  },
  methods: {
    fetchData() {
      if (this.$debug) console.log('Loading form: ', this.proposalUniqueLink);
      const vue = this;

      vue.internalIsLoading = true;
      vue.$store
        .dispatch('meta/FETCH_META', vue.proposalUniqueLink)
        .then(() => {
          vue.routeToCorrectPage();
          vue.initProposal();
        })
        .catch(() => {
          vue.$store.commit('fill/setFormLoadError'); // feel this should be in meta/FETCH_META action
        })
        .then(() => {
          // Always executed
          vue.internalIsLoading = false;
        });
    },
    routeToCorrectPage() {
      if (this.proposalState === 'Active') {
        // active can remain on page
        return;
      }

      if (this.proposalState === 'Completed') {
        // completed so redirect to completed page
        this.$store.commit('meta/setIsRedirect', true);
        this.$router.push(`/completed/${this.proposalUniqueLink}`);
        throw 'Should have redirected to completed page'; // not hit as router will redirect page. This is here just in case
      } else {
        throw 'Not recoginsed proposalState';
      }
    },
    async initProposal() {
      updatePageTitle(this.$store.getters.environmentMenuBar, this.displayTitle);

      const propPromise = this.$store.dispatch('fill/INIT', { proposalIdentifier: this.proposalUniqueLink, editable: true });
      const commentsPromise = this.$store.dispatch('comments/INIT', {
        proposalIdentifier: this.proposalUniqueLink,
        user: 'Client',
      });
      const prefillPromise = this.$store.dispatch('preFill/INIT', { proposalIdentifier: this.proposalUniqueLink });
      await Promise.all([propPromise, commentsPromise, prefillPromise]);

      this.$nextTick(() => {
        const tour = this.$shepherd({
          useModalOverlay: true,
          defaultStepOptions: {
            cancelIcon: {
              enabled: true,
            },
            classes: '',
            scrollTo: { behavior: 'smooth', block: 'center' },
          },
        });

        let skipTour = localStorage.getItem('tourSkipped-' + this.proposalUniqueLink);
        if (skipTour !== 'true' && this.$route.query.skip !== 'true') {
          formTour(tour, this.proposalUniqueLink);
        }
      });

      this.registerForAnswerUpdates();
    },
    registerForAnswerUpdates() {
      this.$proposalHub.proposalOpened(this.proposalUniqueLink);
      this.$proposalHub.$on('answer-changed', this.onAnswerChanged);
      this.$proposalHub.$on('subscribe-list', this.onSubscribeListChange);
    },
    unregisterForAnswerUpdates() {
      if (this.$debug) console.log('Unsubscribe Signalr');
      this.$proposalHub.$off('answer-changed', this.onAnswerChanged);
      this.$proposalHub.$off('subscribe-list', this.onSubscribeListChange);
      this.$proposalHub.proposalClosed(this.proposalUniqueLink);
    },
    onSubmit() {
      this.submitButtonEnabled = false;
      let completed = this.$store.getters['fill/percCompleted'];

      submitProposalDialog(this.$modal, this.$toast, this.$router, this.$api, this.proposalUniqueLink, completed, () => {
        this.submitButtonEnabled = true;
      });
      this.$store.commit('fill/setValidated');
    },
    onAnswerChanged(data) {
      if (this.$debug) console.log('onAnswerChanged', data);
      this.$store.commit('fill/setAnswer', { id: data.id, value: data.value });
    },
    onSubscribeListChange(data) {
      if (this.$debug) console.log('onSubscribeListChange', data);

      this.proposalAccessList = data.ipAddresses;
    },
  },
};
</script>

<style scoped>
.eb-large {
  width: 150px;
}
</style>
