
export default function reopenProposalDialog(formUid){
  this.$modal.show('dialog', {
    title: 'Reopen Application',
    text: 'This will mark the form as no longer completed and allow you to make changes. Are you sure?',
    buttons: [
      {
        title: 'Yes',
        handler: () => {
          this.$modal.hide('dialog');

          const toastId = this.$toast('Submiting. Please wait...', {
            timeout: false,
            icon: 'fas fa-rocket',
            hideProgressBar: true
          });

          this.$api.proposal.postReopenForm(formUid).then(() => {
            this.$toast.dismiss(toastId);
            this.$router.push(`/landing/${formUid}`);
          });
        }
      },
      {
        title: 'Cancel',
        handler: () => {
          this.$modal.hide('dialog');
        }
      }
    ]
  });
}