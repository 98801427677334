<template>
  <div>
    <ul class="appendix-comments-question-group">
      <li>{{ section }}</li>
      <ul v-if="subsection !== null">
        <li>{{ subsection }}</li>
        <ul>
          <li>{{ question }}</li>
          <VCommentPrintList :comments="comments"></VCommentPrintList>
        </ul>
      </ul>
      <ul v-else>
        <li>{{ question }}</li>
        <VCommentPrintList :comments="comments"></VCommentPrintList>
      </ul>
    </ul>
  </div>
</template>

<script>
import VCommentPrintList from '@survey/puppet/comments/ui/VCommentPrintList.vue';

export default {
  components: {
    VCommentPrintList
  },
  name: 'VCommentsAppendixQuestion',
  props: {
    section: String,
    subsection: String,
    question: String,
    comments: Array
  }
};
</script>

<style scoped>
li {
  font-size: 1rem;
}

ul {
  list-style: none;
}

.list-style {
  list-style: none;
}

.appendix-comments-question-group {
  page-break-inside: avoid;
}
</style>
