<template>
  <ul class="pt-3">
    <li v-for="comment in comments" :key="comment.id">
      <VCommentPrint :message="comment.message" :createdAt="comment.createdAt" :author="comment.author"></VCommentPrint>
    </li>
  </ul>
</template>

<script>
import VCommentPrint from '@survey/puppet/comments/ui/VCommentPrint.vue';

export default {
  components: {
    VCommentPrint
  },
  name: 'VCommentPrintList',
  props: {
    comments: Array
  }
};
</script>

<style scoped>
li {
  font-size: 1rem;
}

ul {
  list-style: none;
}

.list-style {
  list-style: none;
}

.appendix-comments-question-group {
  page-break-inside: avoid;
}
</style>
