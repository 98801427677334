import axios from '@shared/services/api/axiosConfig.js';
import { apiPathStart } from '@survey/services/api/surveyApiHelper.js'
const debug = process.env.NODE_ENV !== 'production';

export default {
  // Unique to survey
  async downloadProposal(propUniqueLink) {
    const res = await axios.get(`/api/external/proposal/${propUniqueLink}/pdf`, {
      responseType: 'arraybuffer'
    });

    let blob = new Blob([res.data], { type: 'application/*' });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'Application Form.pdf';
    link.click();
    URL.revokeObjectURL(link.href);
    
    return res;
  },
  async getProposalMeta(propUniqueLink) {
    if(debug) console.log(`getProposalMeta ${propUniqueLink}`)
    const res = await axios.get(`/api/external/proposal/${propUniqueLink}/meta`)
    return res;
  },
  async getIsDownloadlinkReady(propUniqueLink) {
    const res = await axios.get(`/api/external/proposal/${propUniqueLink}/downloadlinkready`)
    return res;
  },
  async postSubmitForm(propUniqueLink){
    const res = await axios.post(`/api/external/proposal/${propUniqueLink}/submit`);
    return res;
  },
  async postReopenForm(propUniqueLink){
    const res = await axios.post(`/api/external/proposal/${propUniqueLink}/reopen`);
    return res;
  },

  // Common to both controllers
  async getProposalFill(propUniqueLink){
    const res = await axios.get(`/api/external/proposal/${propUniqueLink}`)
    if (debug) console.log('getProposalFill', res);
    return res;
  },
  async postAnswer(propUniqueLink, payload){
    const res = await axios.post(`/api/external/proposal/${propUniqueLink}/answer`, payload, {errorHandle: false})// disable global error handler
    return res;
  },
  async postRetryAnswers(propUniqueLink, payload){
    const res = await axios.post(`/api/external/proposal/${propUniqueLink}/retryAnswers`, payload, {errorHandle: false})// disable global error handler
    return res;
  },
  async deleteSupportingDoc(propUniqueLink, supportingDocId){
    const res = await axios.delete(`/api/external/proposal/${propUniqueLink}/deleteDoc/${supportingDocId}`)
    return res;
  },

  urlSupportingDocUpload(propUniqueLink, questionId) {
    let initPath = `/api/external/proposal/${propUniqueLink}/SupportingDoc/${questionId}`;
    let fullPath = apiPathStart() + initPath
    return fullPath;
  },
  urlSupportingDocDownload(propUniqueLink, supportingDocId) {
    let initPath = `/api/external/proposal/${propUniqueLink}/downloadDoc/${supportingDocId}`;
    let fullPath = apiPathStart() + initPath
    return fullPath;
  },
}
