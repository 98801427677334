<template>
  <div class="form-group">
    <div :class="isQuestionType ? 'question-group-print' : ''">
      <component :is="componentType" v-bind="proposalItem"></component>
      <VCommentPrintList v-if="showComments" :comments="filteredComments"></VCommentPrintList>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import questionsHelper from '@shared/components/questions/utils/questionsHelper.js';
import VCommentPrintList from '@survey/puppet/comments/ui/VCommentPrintList.vue';


import QDate from '@shared/components/questions/ui/QDate.vue';
import QUpload from '@shared/components/questions/ui/QUpload.vue';
import QuestionSubSection from '@shared/components/questions/ui/QuestionSubSection.vue';
import QShortPrint from '@shared/components/questions/ui/QShortPrint.vue';
import QLongReadonly from '@shared/components/questions/ui/QLongReadonly.vue';
import QYesNoReadonly from '@shared/components/questions/ui/QYesNoReadonly.vue';
import QOptionReadonly from '@shared/components/questions/ui/QOptionReadonly.vue';
import QCheckReadonly from '@shared/components/questions/ui/QCheckReadonly.vue';
import QTablePrint from '@shared/components/questions/ui/QTablePrint.vue';
import QSignPrint from '@shared/components/questions/ui/QSignPrint.vue';

export default {
  components: {
    QDate,
    QUpload,
    QuestionSubSection,
    QShortPrint,
    QYesNoReadonly,
    QCheckReadonly,
    QOptionReadonly,
    QLongReadonly,
    QTablePrint,
    QSignPrint,
    VCommentPrintList,
  },
  props: {
    proposalItem: Object,
  },
  computed: {
    ...mapState('fill', ['componentsMode','printAppendix' ]),
    isQuestionType() {
      return questionsHelper.isQuestionType(this.proposalItem.type);
    },
    filteredComments() {
      return this.$store.getters['comments/comments'](this.proposalItem.id);
    },
    showComments(){
      return !this.printAppendix && this.filteredComments.length>0 ;
    },
    componentType() {
      if (this.proposalItem.type in questionsHelper.printComponentsDict()) 
        return questionsHelper.printComponentsDict()[this.proposalItem.type];

      return 'VNone';
    }
  },
};



</script>

<style scoped></style>
