<template>
  <div>
      <h2>Comments</h2>
      <div v-for='(content) in commentsAppendixData' :key='content.questionId'>
        <VCommentsAppendixQuestion v-bind='content'></VCommentsAppendixQuestion>
        <hr />
      </div>
  </div>
</template>

<script>
import { commentsAppendixData } from '@survey/puppet/comments/utils/appendixCommentsHelper.js';
import VCommentsAppendixQuestion from '@survey/puppet/comments/ui/VCommentsAppendixQuestion.vue';
import { mapState, mapGetters } from 'vuex';


export default {
  components: {
    VCommentsAppendixQuestion,
  },
  data() {
    return {
      displayComponents: []
    }
  },
  computed: {
    ...mapState('comments', ['comments']),
    ...mapState('fill', ['form']),
    ...mapGetters('fill', ['shouldDisplayElem']),
    commentsAppendixData(){
      var filterFunction = (elem) => this.shouldDisplayElem(elem);
      const result = commentsAppendixData(this.form, this.comments, filterFunction )
      if (this.$debug) console.log(result)
      return result;
    }
  },
}
</script>

<style scoped>

</style>
